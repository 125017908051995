// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { NOTIFICATION_CATEGORIES, NOTIFICATION_INTENTS } from './PropTypes';

export default notification => {
    switch (notification.category) {
        case NOTIFICATION_CATEGORIES.ALERT:
            return NOTIFICATION_INTENTS.DANGER;
        case NOTIFICATION_CATEGORIES.WARNING:
            return NOTIFICATION_INTENTS.WARNING;
        case NOTIFICATION_CATEGORIES.FEEDBACK:
        case NOTIFICATION_CATEGORIES.PROMOTION:
            return NOTIFICATION_INTENTS.INFO;
    }

    return NOTIFICATION_INTENTS.INACTIVE;
};
