// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { unescapeHtml } from 'jsw';
import stripTags from 'helpers/stripTags';

const getTitleForHelpSearchQuery = ({
    wizardTitle,
    pageTitle,
    breadcrumbs = [],
}) => {
    let title = wizardTitle ?? pageTitle ?? breadcrumbs[breadcrumbs.length - 1]?.title ?? '';

    let objectName;
    const match = title.match(/<b>(.+)<\/b>/i);
    if (match) {
        objectName = match[1];
        title = title.substring(0, match.index).trim();
    }

    const matchLink = title.match(/(<a(.+)<\/a>)/);
    if (matchLink) {
        if (matchLink.index + matchLink[0].length === title.length) {
            objectName = stripTags(matchLink[0]);
        }

        title = title.replace(matchLink[0], '').trim();
    }

    if (objectName) {
        // (1) If a Plesk page title contains some text and an object name then the input value is set to this text excluding one word preceding the object name.
        title = title.substring(0, title.lastIndexOf(' '));
    }

    if (title === '' && breadcrumbs.length > 2) {
        // (2) If a Plesk page title is empty or contains an object name only then the input value is set to the value of the last link in the breadcrumbs.
        title = breadcrumbs[breadcrumbs.length - 2]?.title ?? '';
    }

    return stripTags(unescapeHtml(title)).trim();
};

export default getTitleForHelpSearchQuery;
