// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useState, useEffect, useRef } from 'react';
import { pleskWS, UAT, Locale } from 'jsw';
import { Badge, Button, Popover, Heading, Columns, Column, Label, Action, Link, Icon } from '@plesk/ui-library';
import { useQuery } from '@apollo/client';
import { useModal } from 'hooks';
import applyFilter from './lib/applyFilter';
import { deleteNotification, listNotifications, updateNotifications } from './lib/api';
import NotificationCenterView from './NotificationCenterView';
import NotificationCenterList from './NotificationCenterList';
import { NOTIFICATION_CATEGORIES } from './lib/PropTypes';
import getIntentByNotification from './lib/getIntentByNotification';
import getMostIntentByNotifications from './lib/getMostIntentByNotifications';
import NotificationToast from './NotificationToast';
import { isNotificationCritical, isAllowedFeedbackOrPromotionNotification } from './lib/utils';
import NotificationActions from './NotificationActions';
import NotificationDate from './NotificationDate';
import { addToast, removeToast } from 'helpers/toasts';
import { useNavigate } from 'react-router-dom';

import NOTIFICATION_CENTER_QUERY from 'queries/NotificationCenter.graphql';

const MAX_SHOW = 3;
const RELOAD_TIMEOUT = 30000;

const Translate = Locale.getTranslate('components.notification-center');

const NotificationCenter = () => {
    const [isListOpen, setIsListOpen] = useModal('notification-center-list');
    const [selectedNotificationId, setSelectedNotificationId] = useModal('notification-center-view');
    const [notifications, setNotifications] = useState([]);
    const [filter, setFilter] = useState({});
    const {
        data: {
            config,
            viewer: {
                type: viewerType,
                permissions: { manageServerNotifications },
            },
        },
    } = useQuery(NOTIFICATION_CENTER_QUERY);
    const toastsRef = useRef([]);
    const navigate = useNavigate();

    let ws;
    let reloadTimeout;

    useEffect(() => {
        initFilter();
        if (config.webSocket.enabled) {
            initWebSocket();
        } else {
            loadNotifications();
        }

        return () => {
            if (reloadTimeout) {
                clearTimeout(reloadTimeout);
            }
            if (ws) {
                ws.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initFilter = () => {
        const filter = JSON.parse(window.localStorage.getItem('notification-center-filter')) || {};
        setFilter(filter);
    };

    const handleNewNotificationRef = useRef();
    useEffect(() => {
        handleNewNotificationRef.current = newNotification => {
            setNotifications([newNotification, ...notifications]);
        };
    }, [notifications]);

    useEffect(() => {
        const allToasts = notifications
            .filter(notification => !notification.isViewed && (
                isNotificationCritical(notification) || isAllowedFeedbackOrPromotionNotification(notification)),
            )
            .map(notification => {
                const key = `notification-${notification.id}`;

                return {
                    'data-type': 'notification-toast',
                    key,
                    intent: getIntentByNotification(notification),
                    accent: isAllowedFeedbackOrPromotionNotification(notification),
                    icon: notification.contextIconUrl ? <Icon src={notification.contextIconUrl} size="64" /> : undefined,
                    message: (
                        <NotificationToast
                            notification={notification}
                            onSelectNotification={() => {
                                if (isListOpen === null) {
                                    handleOpenNotificationCenter();
                                }
                                handleSelectNotification({ notification });
                                removeToast({ key });
                            }}
                        />
                    ),
                    autoClosable: notification.category !== NOTIFICATION_CATEGORIES.ALERT && !isAllowedFeedbackOrPromotionNotification(notification),
                    onClose: () => handleUpdateNotifications([notification.id], { isViewed: true }),
                };
            });

        const toasts = allToasts.length <= MAX_SHOW
            ? allToasts
            : [
                ...allToasts.slice(0, MAX_SHOW),
                {
                    key: 'notification-all',
                    message: (
                        <div className="notification-center-toast__content">
                            <Icon className="notification-center-toast__content-icon" name="list" />
                            <Link
                                onClick={handleOpenNotificationCenter}
                            >
                                <Translate
                                    content="moreToasts"
                                    params={{
                                        total: allToasts.length - 3,
                                    }}
                                />
                            </Link>
                        </div>
                    ),
                    autoClosable: false,
                    onClose: handleMarkAllAsViewed,
                },
            ];

        if (JSON.stringify(toastsRef.current) !== JSON.stringify(toasts)) {
            toastsRef.current.forEach(removeToast);
            toasts.forEach(addToast);
            toastsRef.current = toasts;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    const initWebSocket = () => {
        ws = pleskWS.bind({
            actions: {
                // eslint-disable-next-line camelcase
                panel_notification_created: newNotification => {
                    handleNewNotificationRef.current(newNotification);
                },
            },
            onOpen: () => loadNotifications(),
        });
    };

    const loadNotifications = (id = 0) => {
        if (reloadTimeout) {
            clearTimeout(reloadTimeout);
        }
        listNotifications(id)
            .then(newNotifications => {
                setNotifications([...newNotifications, ...notifications]);
            })
            .then(() => {
                if (config.webSocket.enabled && ws.isReady()) {
                    return;
                }
                reloadTimeout = window.setTimeout(
                    () => loadNotifications(notifications.length ? notifications[0].id : 0),
                    RELOAD_TIMEOUT,
                );
            })
            .catch(() => {
                reloadTimeout = window.setTimeout(
                    () => loadNotifications(notifications.length ? notifications[0].id : 0),
                    RELOAD_TIMEOUT,
                );
            });
    };

    const handleOpenSettings = () => {
        handleCloseNotificationCenter();
        navigate('/cp/notifications/panel-settings?context=tools');
    };

    const handleUpdateFilter = newFilter => {
        window.localStorage.setItem('notification-center-filter', JSON.stringify(newFilter));
        setFilter({ ...filter, ...newFilter });
    };

    const handleUpdateNotifications = (ids, change) => {
        setNotifications(notifications.map(notification => {
            if (ids.indexOf(notification.id) < 0) {
                return notification;
            }
            return {
                ...notification,
                ...change,
            };
        }));

        updateNotifications(ids, change);
    };

    const handleSelectNotification = ({ notification }) => {
        UAT.dispatchAction('NOTIFICATION_DETAILS', {
            type: notification.code,
            parents: ['notification-center-list'],
            messageKey: notification.messageKey,
        });
        handleUpdateNotifications([notification.id], { isRead: true, isViewed: true });
        setSelectedNotificationId(notification.id);
    };

    const handleMarkAllAsRead = () => {
        const ids = notifications.filter(notification => !notification.isRead).map(notification => notification.id);
        handleUpdateNotifications(ids, { isRead: true });
    };

    const handleMarkAllAsViewed = () => {
        const ids = notifications.filter(notification => !notification.isViewed).map(notification => notification.id);
        handleUpdateNotifications(ids, { isViewed: true });
    };

    const handleRemoveNotification = id => {
        setNotifications(notifications.filter(notification => notification.id !== id));
        if (selectedNotificationId === id) {
            setSelectedNotificationId(null);
        }

        deleteNotification(id);
    };

    const handleOpenNotificationCenter = () => {
        handleMarkAllAsViewed();
        setIsListOpen(true);
    };

    const handleCloseNotificationCenter = () => {
        setSelectedNotificationId(null);
        setIsListOpen(null);
    };

    const handleCloseNotificationView = () => {
        setSelectedNotificationId(null);
    };

    const unreadNotifications = applyFilter(notifications, { isUnRead: true });
    const selectedNotification = notifications.find(({ id }) => id === Number(selectedNotificationId));

    const button = (
        <Badge
            label={String(unreadNotifications.length)}
            intent={getMostIntentByNotifications(unreadNotifications)}
            hidden={unreadNotifications.length === 0}
        >
            <Button
                icon="bell"
                onClick={isListOpen === null ? handleOpenNotificationCenter : handleCloseNotificationCenter}
                ghost
            />
        </Badge>
    );

    return (
        <Popover
            target={button}
            placement="bottom"
            visible={isListOpen !== null}
            onClose={handleCloseNotificationCenter}
            width="sm"
            height={600}
            extra={selectedNotification ? (
                <NotificationActions
                    notification={selectedNotification}
                    onUpdateNotifications={handleUpdateNotifications}
                    onRemoveNotification={handleRemoveNotification}
                />
            ) : (viewerType === 'ADMIN' && manageServerNotifications ? (
                <Action icon="gear" onClick={handleOpenSettings}>
                    <Translate content="settings" />
                </Action>)
                : null
            )}
            title={selectedNotification ? (
                <div className="notification-center-heading">
                    <div className="notification-center-heading__top">
                        <Button
                            icon="arrow-left"
                            onClick={handleCloseNotificationView}
                            data-action="NOTIFICATION_TO_LIST"
                            data-type={selectedNotification.code}
                            ghost
                            className="notification-center-heading__back-button"
                        />
                        {' '}
                        <Heading level={3}>{selectedNotification.subject}</Heading>
                    </div>
                    <Columns gap="md" className="notification-center-heading__bottom" vertical={false}>
                        <Column>
                            {isNotificationCritical(selectedNotification) ? (
                                <Label intent={getIntentByNotification(selectedNotification)} className="notification-center-heading__label">
                                    <Translate content={`category${selectedNotification.category}`} />
                                </Label>
                            ) : null}
                            {selectedNotification.context}
                        </Column>
                        <Column>
                            <NotificationDate notification={selectedNotification} />
                        </Column>
                    </Columns>
                </div>
            ) : (
                <Heading level={3}>
                    <Translate content="title" />
                </Heading>
            )}
            data-type={selectedNotification ? 'notification-center-view' : 'notification-center-list'}
            data-id={selectedNotification ? selectedNotification.id : null}
        >
            {selectedNotification ? (
                <NotificationCenterView
                    notification={selectedNotification}
                />
            ) : (
                <NotificationCenterList
                    notifications={notifications}
                    filter={filter}
                    onUpdateNotifications={handleUpdateNotifications}
                    onUpdateFilter={handleUpdateFilter}
                    onSelectNotification={handleSelectNotification}
                    onRemoveNotification={handleRemoveNotification}
                    onMarkAllAsRead={handleMarkAllAsRead}
                />
            )}
        </Popover>
    );
};

export default NotificationCenter;
